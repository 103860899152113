import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/scaffolding/seo'

const NotFoundPage = () => {
  return (
    <>
      <SEO title="Page Not Found" htmlClass="is-404" />
      <section className="content content-404">
        <div className="wrapper">
          <h1>
            <span>Oh snap...</span>
            <span>Can we start over?</span>
          </h1>
          <Link to="/" className="link-404">
            <span>Let’s Go</span>
          </Link>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
